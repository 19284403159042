import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'
import { s3Layer } from 'vue3-layer';
import 'vue3-layer/dist/s3Layer.css';
import Qs from 'qs'
import moment from 'moment'


const app = createApp(App)
app.config.globalProperties.$axios = axios
app.config.globalProperties.qs = Qs
app.config.globalProperties.moments = moment

// 中文转换

import locale from "element-plus/lib/locale/lang/zh-cn";
app.use(ElementPlus, {locale});

app.use(store).use(router).use(ElementPlus).mount('#app')
app.component('s3-layer', s3Layer);
