<template>
  <div class="entirety">
    <el-header>
      <Head></Head>
    </el-header>
    <el-container>
      <div class="cat">
        <el-menu
          :default-active="onRoutes"
          class="el-menu-vertical-demo"
          :collapse="isCollapse"
          background-color="rgb(50, 65, 87)"
          unique-opened
          router
        >
          <template class="menu-bug" v-for="item in menus" :key="item.index" >
            <template v-if="item.subs">
              <el-sub-menu :index="item.index" :key="item.index">
                <template #title>
                  <img :src="item.ioc" style="width: 20px;height: 20px;margin-right: 20px;" alt="">
                  <span>{{ item.title }}</span>
                </template>
                <template v-for="second in item.subs" :key="second">
                  <el-menu-item-group>
                    <el-menu-item :index="second.index">{{
                      second.title
                    }}</el-menu-item>
                  </el-menu-item-group>
                </template>
              </el-sub-menu>
            </template>
            <template v-else>
              <el-menu-item :index="item.index">
                <img :src="item.ioc" style="width: 20px;height: 20px;margin-right: 20px;" alt="">
                <template #title>
                  <p>{{ item.title }}</p>
                </template>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </div>
      <el-main>
        <Label></Label>
        <!-- <router-view v-slot="{ Component }">
          <keep-alive v-if="route.meta.keepAlive"> 
            <component :is="Component" />
          </keep-alive>
          <component :is="Component" v-else="!route.meta.keepAlive"/>
        </router-view> -->


        <!-- <router-view></router-view> -->

        

        <router-view v-slot="{ Component }">
          <keep-alive :include="favour">
            <component :is="Component"/>
          </keep-alive>
        </router-view>
      </el-main>
    </el-container>
    <!-- 下载任务浮窗 -->
    <div class="floating" @click="ocean">
      <img class="images" src="../assets/xiazai.png" alt="">
    </div>
    <!-- 任务执行浮窗 -->
    <div class="execute" @click="sentence">
      <img class="images1" src="../assets/renwu.png" alt="">
    </div>
  </div>
  <!-- 任务执行弹窗内容 -->
    <el-dialog v-model="carry" width="50%">
      <template #header>
        <div class="minitype">系统任务</div>
        <div class="trousers">
          <img class="tishi" src="../assets/tishi.png" alt="">
          <div>系统任务日志仅当天有效，过期自动删除</div>
        </div>
      </template> 
      
        <div class="situation">
          <el-table :data="assignment" style="width: 100%" :header-cell-style="{ background: '#F5F7FA' }" border :empty-text="mission">
            <el-table-column prop="createTime" label="提交任务时间" align="center" :formatter="sun" :show-overflow-tooltip='true' />
            <el-table-column prop="type" label="类型" align="center" :show-overflow-tooltip='true' />
            <!-- <el-table-column prop="fileName" label="文件名"  align="center" :show-overflow-tooltip='true'/> -->
            <el-table-column prop="state" label="状态" :formatter="condition1"  align="center" :show-overflow-tooltip='true'/>
            <!-- <el-table-column label="操作" prop="state" align="center" width="100" >
              <template #default="scope">
                <el-button
                  v-show="scope.row.state==1"
                  size="default"
                  @click="copyreader(scope.row.link)"
                  class="bulur"
                  ><el-icon class="cancel"> <Download /> </el-icon>下载</el-button
                >
                <div v-show="scope.row.state==2">-</div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="carry = false">
              关闭
            </el-button>
          </span>
        </template>
    </el-dialog>
  <!-- 下载任务弹窗内容 -->
    <el-dialog v-model="centre" width="50%">
      <template #header>
        <div class="minitype">预约下载中心</div>
        <div class="trousers">
          <img class="tishi" src="../assets/tishi.png" alt="">
          <div>文件生成后仅当天有效，过期自动删除</div>
        </div>
      </template> 
      
        <div class="situation">
          <el-table :data="table" style="width: 100%" :header-cell-style="{ background: '#F5F7FA' }" border :empty-text="subscribe">
            <el-table-column prop="createTime" label="申请时间"  width="150" align="center" :formatter="tiems" :show-overflow-tooltip='true' />
            <el-table-column prop="type" label="类型" align="center" :show-overflow-tooltip='true' />
            <el-table-column prop="fileName" label="文件名"  align="center" :show-overflow-tooltip='true'/>
            <el-table-column prop="state" label="状态" :formatter="condition"  align="center" :show-overflow-tooltip='true'/>
            <el-table-column label="操作" prop="state" align="center" width="100" >
              <template #default="scope">
                <el-button
                  v-show="scope.row.state==1"
                  size="default"
                  @click="copyreader(scope.row.link)"
                  class="bulur"
                  ><el-icon class="cancel"> <Download /> </el-icon>下载</el-button
                >
                <div v-show="scope.row.state==2">-</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="centre = false">
              关闭
            </el-button>
          </span>
        </template>
    </el-dialog>
</template> 
<script>

// import { ref } from "vue";
// import { UserFilled } from "@element-plus/icons-vue";
// import {cacheRouter } from '../router/permission.js'
import { computed, onMounted, onUnmounted, nextTick, provide, ref,reactive} from "vue";
import { useStore } from "vuex";
import Head from "./head.vue";  
import Label from "./label.vue";
import { useRoute,useRouter } from "vue-router";
import { XZall,RWall } from "../utils/api"
import moments from 'moment'
export default {
  setup() {
    // 任务执行弹窗
    const carry=ref(false)
    // 任务弹窗表格提示
    const mission=ref('暂无数据')



    // 任务时间转换
    let sun=(row, colum,cellValue )=>{
    if(typeof cellValue=='number'){
      return moments(cellValue*1000).format("YYYY-MM-DD HH:mm")
    }
    }



   // 下载时间转换
   let tiems=(row, colum,cellValue )=>{
    if(typeof cellValue=='number'){
      return moments(cellValue*1000).format("YYYY-MM-DD HH:mm")
    }
    }


    // 弹窗下载点击
    const copyreader=(scope)=>{
      let iframe = document.createElement('iframe')
      iframe.src=scope
      iframe.style.display = 'none'
      document.body.appendChild(iframe)
      setTimeout(() => {
        document.body.removeChild(iframe)
      }, 1000)
      // window.open(scope)
    }


    // 任务发布状态
    let condition1=(row, colum,cellValue)=>{
      console.log(cellValue)
       if (cellValue == 1) {
        return "执行完毕";
      } else if (cellValue === 2) {
        return "执行中";
      }
    }
    // 下载发布状态
    let condition=(row, colum,cellValue)=>{
       if (cellValue == 1) {
        return "已完成";
      } else if (cellValue === 2) {
        return "生成中";
      }
    }
    // 预约下载弹窗表格提示
    const subscribe=ref('加载中')

    const table = ref([])
    // 获取下载列表
    const all =()=>{
      XZall().then((res)=>{
        console.log(res)
        if(res.data.code==0){
          table.value=res.data.data.fileExportList
          if(table.value.length==0){
            subscribe.value='暂无数据'
          }
          
        }else{
          ElMessage({
            message: res.data.msg,
            type: "error",
            center: true,
          });
        }
      })
    }
    // 任务执行表格数据
    const assignment=ref([])
    // 获取系统查询任务
    const missions=()=>{
      RWall().then((res)=>{
        console.log(res)
        if(res.data.code==0){
          assignment.value=res.data.data.systemTasksList
          if(table.value.length==0){
            subscribe.value='暂无数据'
          }
          
        }else{
          ElMessage({
            message: res.data.msg,
            type: "error",
            center: true,
          });
        }
      })
    }
    // 任务浮窗点击
    const sentence=()=>{
      carry.value=true
      missions()
    }

    // 下载浮窗点击
    let ocean=()=>{
      centre.value=true
      all()
    }

    // 预约下载中心弹窗
    let centre=ref(false)









    const route = useRoute();
    const router = useRouter();
    // 缓存
    const report=ref([])
    const test=ref([])
    const favour=ref([])  

    // console.log($store.route)
    
    // 监听屏幕变化
    const width = ref(0)
    let cat= ref(true)
    function onResize() {
      width.value = window.innerWidth;
      // console.log(width)
      if(width.value<500){
        cat=false
      }
    }

    onMounted(() => {
      window.addEventListener("resize", onResize);
      onResize();
      // console.log(favour.value)
    });
    onUnmounted(() => {
      window.removeEventListener("resize", onResize);
    });
    // 左侧菜单栏显示内容
    const menus = [
      {
        index: "/homepage",
        title: "系统首页",
        meta:{
          active:'0',
        },
        ioc:require("../assets/shouye.png"),
      },
      {
        index: "1",
        title: "工作管理",
        
        ioc:require("../assets/gonghui.png"),
        subs: [
          {
            index: "/branch",
            title: "组织管理",
            meta:{
              active:'1'
            },
            ioc:require("../assets/gonghui.png"),
          },
          {
            index: "/member",
            title: "会员管理",
            meta:{
              active:'2'
            },
            ioc:require("../assets/renyuan.png"),
          },
          {
            index: "/dynamic",
            title: "工会动态",
            ioc:require("../assets/dongtai.png"),
          },
          {
            index: "/advanced",
            title: "先进典型",
            ioc:require("../assets/dianxing.png"),
          },
          {
            index: "/payment",
            title: "会费缴纳",
            ioc:require("../assets/jiaona.png"),
          },
        ],
      },
      // {
      //   index: "3",
      //   title: "工作管理",
      //   ioc:require("../assets/gongzuo.png"),
      //   subs: [
      //     {
      //       title: "文体协会",
      //     },
      //     {
      //       title: "会员关爱",
      //     }
      //   ],
      // },
      {
        index: "4",
        title: "活动管理",
        
        ioc:require("../assets/huodong.png"),
        subs: [
          {
            title: "健步运动",
            index: "/walk",
          },
          {
            index: "/elegant",
            title: "活动风采",
            ioc:require("../assets/fengcai.png"),
          },
          {
            index: "/answer",
            title: "答题问卷",
            ioc:require("../assets/dati.png"),
          },
		  {
		    index: "/eventRegistration",
		    title: "日常活动举办",
		  },
        ],
      },
      {
        index: "5",
        title: "协会管理",
        
        ioc:require("../assets/xiehui.png"),
        subs: [
			{
			  title: "协会管理",
			  index: "/associationManagement",
			},
			{
			  title: "会员协会统计",
			  index: "/membershipStatistics",
			},
			{
			  title: "往期回顾",
			  index: "/WQlook",
			}
        ],
      },
      {
        index: "6",
        title: "服务管理",
        
        ioc:require("../assets/fuwu.png"),
        subs: [
          {
            title: "子女托管",
            index: "/ZNtrustee",
          },
		  {
		    title: "我为群众办实事",
		    index: "/doingThings",
		  }
        ],
      },
      {
        index: "7",
        title: "福利管理",
        
        ioc:require("../assets/jifen.png"),
        subs: [
          {
            title: "积分管理",
            index: "/JFstatistics",
          },
          {
            title: "余额统计",
            index: "/YEremaining",
          },
          {
            title: "消费统计",
            index: "/XFstatistics",
          }
        ],
      },
      {
        index: "8",
        title: "通用管理",
        
        ioc:require("../assets/tongyong.png"),
        subs: [
          {
            title: "支付管理",
            index: "/HFargu",
          },
          {
            title: "管理员管理",
            index: "/TYargu",
          },
          {
            title: "流程管理",
            index: "/process",
          },
		  // {
		  //   title: "短信推送管理",
		  //   index: "/smsPush",
		  // }
        ],
      },
      {
        index: "9",
        title: "系统管理",
        
        ioc:require("../assets/setup.png"),
        subs: [
          {
            title: "管理员登录日志",
            index: "/LLargu",
          }
        ],
      },
    ];
    const getShowTask = computed (()=>{
      return store.state.tagsList;
    })

    const onRoutes = computed(() => {
      test.value=[]
      report.value=store.state.tagsList
      report.value.forEach((item)=>{
        test.value.push(item.name)
      })
      favour.value=test.value
      

      return route.meta.active;

    });

    // 头部鼠标移入弹窗
    const store = useStore();
    const isCollapse = computed(() => store.state.isCollapse);


    const states = reactive({
      showRouter: true
    })



    
    function reload(){
      states.showRouter = false
      //nextTick：在下次 DOM 更新循环结束之后执行延迟回调。在修改数据之后立即使用这个方法，获取更新后的 DOM。
      nextTick( () =>{
        states.showRouter = true
      })
    }

	// 向子组件以及子孙组件传递名为reload的函数，第一个参数自定义，第二个参数代表上面定义的reload()方法
    provide('reload',reload)

    return {
      condition1,
      sun,
      tiems,
      // 任务执行点击/浮窗/弹窗
      sentence,
      assignment,
      carry,
      mission,
      // 下载浮窗点击
      condition,
      copyreader,
      table,
      subscribe,
      ocean,
      centre,




      favour,
      states,
      route,
      // cacheRouter,
      cat,
      width,
      isCollapse,
      // 左侧菜单
      menus,
      onRoutes,
    };
  },
  components: {
    // 标签栏
    Label,
    // 头部
    Head,
  },
};
</script>
<style scoped lang="scss">
 .situation{
  padding-bottom: -50px !important;
  height: 215px !important;
  overflow: auto !important;
}
.situation::-webkit-scrollbar{
  display: none !important;
} 
 /* .el-dialog__body{
  padding: 10px 10px 10px 10px !important;
  height: 215px !important;
  overflow: auto !important;
}
.el-dialog__body::-webkit-scrollbar{
  display: none !important;
} */

.tishi{
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.trousers{
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 15px;
}
.minitype{
  font-size: 16px;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
.images{
  width: 20px;
  height: 20px;
}
.images1{
  width: 22px;
  height: 23px;
}
.floating{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0%;
  top: 50%;
  width: 40px;
  height: 40px;
  background-color:#409EFF;
  border-radius: 5px 0 0 5px;
  z-index: 10;
}
.execute{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0%;
  top: 56%;
  width: 40px;
  height: 40px;
  background-color:#409EFF;
  border-radius: 5px 0 0 5px;
  z-index: 10;
}
.cat{
  background-color: rgb(50, 65, 87);
}
.el-aside {
  // 侧边栏折叠动画速度
  transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -moz-transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;
}
// 头部
.el-main {
  height: calc(100vh - 70px);
  background-color: #f0f0f0;
  padding: 0;
  // overflow: hidden;
}
.el-header {
  padding: 0;
  background-color: #242f42;
  height: 70px;
}
// 左侧菜单
.el-menu {
  border: none;
  height: calc(100vh - 70px);
}
.el-container {
  display: flex;
  height: 100%;
}
.entirety {
  display: flex;
  flex-direction: column;
  // height: 100vh;
}
::v-deep .el-sub-menu__title {
  color: rgb(191, 203, 217);
}
::v-deep .el-menu-item-group__title {
  padding: 0px !important;
}
::v-deep .el-menu-item .el-menu-tooltip__trigger {
  width: 112% !important;
}
.aa {
  display: flex;
  align-items: center;
}
.left::-webkit-scrollbar {
  display: none;
}
.el-menu--collapse {
  height: calc(100vh - 70px);
  background-color: rgb(50, 65, 87);
}
::v-deep .el-sub-menu__title:hover {
  background: #283446 !important;
}
::v-deep .el-menu-item:hover {
  background: #283446 !important;
  color: rgb(191, 203, 217) !important;
}
.el-submenu__title:hover {
  background: #283446 !important;
  color: rgb(191, 203, 217) !important;
}
.el-menu-item.is-active {
  background: #283446 !important;
  color: rgb(32, 160, 255) !important;
}
.el-submenu__title.is-active {
  background: #1c88cf !important;
  color: rgb(32, 160, 255) !important;
}
.el-sub-menu .el-menu-item {
  background-color: rgb(50, 65, 87);
}
.el-menu-item {
  color: rgb(191, 203, 217);
  background-color: rgb(50, 65, 87);
}
.el-sub-menu__title {
  color: rgb(191, 203, 217);
}
.el-menu-vertical-demo[data-v-a7306224]:not(.el-menu--collapse)::-webkit-scrollbar {
  display: none;
}
.el-menu-item-group__title {
  padding: 0 !important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
   overflow-y: auto;
  width: 200px;
  // min-height: 400px;
}
.el-menu-vertical-demo:not(.el-menu--collapse)::-webkit-scrollbar {
  display: none;
}
</style>
<style>
.el-menu--popup{
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
</style>