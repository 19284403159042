<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  // overflow-x: hidden;
}
</style>
